import { Message } from 'libs/tinode/types/sdk/Message';

export function getStartMessage(
  messages: Message[] | undefined
): Message | null {
  const startMessage = messages?.[0];
  const isValidStartMessage =
    startMessage?.content?.type === 'system' &&
    startMessage?.content?.message === 'chat started';
  return isValidStartMessage ? startMessage : null;
}
