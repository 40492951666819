import { Tag, Typography } from 'antd';

import {
  LegalStatus,
  PaymentStatus,
  StatusType,
  TabType,
} from 'pages/legal-drafting/types';

type StatusMapping = {
  [key in LegalStatus | PaymentStatus]: {
    bgColor: string;
    color: string;
    text: string;
  };
};
interface StatusTagProps {
  status: LegalStatus | PaymentStatus;
  type?: TabType;
}

const LEGAL_DRAFTS_STATUS_DICT: StatusMapping = {
  created: {
    bgColor: '#ECC12A33',
    color: '#ECC12A',
    text: 'Sedang Diulas',
  },
  in_progress: {
    bgColor: '#ECC12A33',
    color: '#ECC12A',
    text: 'Sedang Diulas',
  },
  // Out of our scope for now, occured when theres any appeal on uploaded documents (?)
  // not_completed: {
  //   bgColor: '#2587FB33',
  //   color: '#2587FB',
  //   text: 'Belum Selesai',
  // },
  in_review: {
    bgColor: '#ECC12A33',
    color: '#ECC12A',
    text: 'Sedang Diulas',
  },
  accepted: {
    bgColor: '#79C04333',
    color: '#79C043',
    text: 'Selesai',
  },
  need_revise: {
    bgColor: '#2587FB33',
    color: '#2587FB',
    text: 'Butuh Revisi',
  },
  in_revise: {
    bgColor: '#ECC12A33',
    color: '#ECC12A',
    text: 'Sedang Direvisi',
  },
  passed_due_date: {
    bgColor: '#ED2B2A33',
    color: '#ED2B2A',
    text: 'Terlewatkan',
  },
  appealed: {
    bgColor: '#ED2B2A33',
    color: '#ED2B2A',
    text: 'Belum Selesai',
  },
  cancelled: {
    bgColor: '#ED2B2A33',
    color: '#ED2B2A',
    text: 'Cancel',
  },
  not_completed: {
    bgColor: '#BBC9D4',
    color: '#181818',
    text: 'Tidak Selesai',
  },

  // Order status
  not_started: {
    bgColor: '#ECC12A33',
    color: '#ECC12A',
    text: 'Pending',
  },
  executing: {
    bgColor: '#ECC12A33',
    color: '#ECC12A',
    text: 'Pending',
  },
  success: {
    bgColor: '#79C04333',
    color: '#79C043',
    text: 'Success',
  },
  failed: {
    bgColor: '#ED2B2A33',
    color: '#ED2B2A',
    text: 'Expired',
  },
  late: {
    bgColor: '#ED2B2A33',
    color: '#ED2B2A',
    text: 'Expired',
  },
};

export const StatusTag = ({ status }: StatusTagProps) => {
  if (!status) return;
  const DICT = LEGAL_DRAFTS_STATUS_DICT;
  return (
    <Tag color={(DICT[status] as StatusMapping[StatusType]).bgColor ?? 'white'}>
      <Typography.Paragraph
        className="hk-margin-bottom-none font-12 weight-400"
        style={{
          color: (DICT[status] as StatusMapping[StatusType]).color ?? 'black',
        }}>
        {(DICT[status] as StatusMapping[StatusType]).text ?? '-'}
      </Typography.Paragraph>
    </Tag>
  );
};
