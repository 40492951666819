/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * HukumkuRestAPI-CaseService
 * OpenAPI spec version: 1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  BodyType,
  ErrorType,
} from '../../../../utils/case-service-request';
import { caseServiceRequest } from '../../../../utils/case-service-request';
import type {
  BookConsultation,
  ConsultationLawyerStatusParams,
  ConsultationLawyerWorkloadStatus,
  Error,
  ForceUpdateConsultationStatusRequest,
  GetConsultations200,
  GetConsultationsAggregate200,
  GetConsultationsId200,
  GetConsultationsParams,
  GetConsultationsTotalClient200,
  GetConsultationsTotalClientParams,
  GetConsultationsUserType200,
  GetConsultationsUserTypeParams,
  OrderWithBasicConsultationResponse,
  ReallocateLawyer,
  SubscribeUser,
  UpdateConsultationStatus,
} from '../../../models/case';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Book consultation
 */
export const postConsultations = (
  bookConsultation: BodyType<BookConsultation>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  const formData = new FormData();
  formData.append('package_id', bookConsultation.package_id);
  formData.append('lawyer_id', bookConsultation.lawyer_id);
  formData.append('topic', bookConsultation.topic);
  formData.append('description', bookConsultation.description);
  if (bookConsultation.supporting_resource !== undefined) {
    formData.append(
      'supporting_resource',
      bookConsultation.supporting_resource
    );
  }
  formData.append('practice_area_id', bookConsultation.practice_area_id);
  if (bookConsultation.voucher_id !== undefined) {
    formData.append('voucher_id', bookConsultation.voucher_id);
  }
  if (bookConsultation.source !== undefined) {
    formData.append('source', bookConsultation.source);
  }

  return caseServiceRequest<OrderWithBasicConsultationResponse>(
    {
      url: `/consultations`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getPostConsultationsMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postConsultations>>,
    TError,
    { data: BodyType<BookConsultation> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postConsultations>>,
  TError,
  { data: BodyType<BookConsultation> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postConsultations>>,
    { data: BodyType<BookConsultation> }
  > = (props) => {
    const { data } = props ?? {};

    return postConsultations(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostConsultationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postConsultations>>
>;
export type PostConsultationsMutationBody = BodyType<BookConsultation>;
export type PostConsultationsMutationError = ErrorType<Error>;

/**
 * @summary Book consultation
 */
export const usePostConsultations = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postConsultations>>,
    TError,
    { data: BodyType<BookConsultation> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions = getPostConsultationsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get list consultation
 */
export const getConsultations = (
  params: GetConsultationsParams,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetConsultations200>(
    { url: `/consultations`, method: 'get', params, signal },
    options
  );
};

export const getGetConsultationsQueryKey = (params: GetConsultationsParams) =>
  [`/consultations`, ...(params ? [params] : [])] as const;

export const getGetConsultationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getConsultations>>,
  TError = ErrorType<Error>,
>(
  params: GetConsultationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConsultations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getConsultations>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConsultationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConsultations>>
  > = ({ signal }) => getConsultations(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetConsultationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConsultations>>
>;
export type GetConsultationsQueryError = ErrorType<Error>;

/**
 * @summary Get list consultation
 */
export const useGetConsultations = <
  TData = Awaited<ReturnType<typeof getConsultations>>,
  TError = ErrorType<Error>,
>(
  params: GetConsultationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConsultations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConsultationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update consultation status
 */
export const patchConsultationsIdStatus = (
  id: string,
  updateConsultationStatus: BodyType<UpdateConsultationStatus>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  const formData = new FormData();
  formData.append('status', updateConsultationStatus.status);

  return caseServiceRequest<void>(
    {
      url: `/consultations/${id}/status`,
      method: 'patch',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getPatchConsultationsIdStatusMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchConsultationsIdStatus>>,
    TError,
    { id: string; data: BodyType<UpdateConsultationStatus> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchConsultationsIdStatus>>,
  TError,
  { id: string; data: BodyType<UpdateConsultationStatus> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchConsultationsIdStatus>>,
    { id: string; data: BodyType<UpdateConsultationStatus> }
  > = (props) => {
    const { id, data } = props ?? {};

    return patchConsultationsIdStatus(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchConsultationsIdStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchConsultationsIdStatus>>
>;
export type PatchConsultationsIdStatusMutationBody =
  BodyType<UpdateConsultationStatus>;
export type PatchConsultationsIdStatusMutationError = ErrorType<Error>;

/**
 * @summary Update consultation status
 */
export const usePatchConsultationsIdStatus = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchConsultationsIdStatus>>,
    TError,
    { id: string; data: BodyType<UpdateConsultationStatus> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions = getPatchConsultationsIdStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Force update consultation status
 */
export const patchConsultationsIdStatusForce = (
  id: string,
  forceUpdateConsultationStatusRequest: BodyType<ForceUpdateConsultationStatusRequest>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<void>(
    {
      url: `/consultations/${id}/status/force`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: forceUpdateConsultationStatusRequest,
    },
    options
  );
};

export const getPatchConsultationsIdStatusForceMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchConsultationsIdStatusForce>>,
    TError,
    { id: string; data: BodyType<ForceUpdateConsultationStatusRequest> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchConsultationsIdStatusForce>>,
  TError,
  { id: string; data: BodyType<ForceUpdateConsultationStatusRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchConsultationsIdStatusForce>>,
    { id: string; data: BodyType<ForceUpdateConsultationStatusRequest> }
  > = (props) => {
    const { id, data } = props ?? {};

    return patchConsultationsIdStatusForce(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchConsultationsIdStatusForceMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchConsultationsIdStatusForce>>
>;
export type PatchConsultationsIdStatusForceMutationBody =
  BodyType<ForceUpdateConsultationStatusRequest>;
export type PatchConsultationsIdStatusForceMutationError = ErrorType<Error>;

/**
 * @summary Force update consultation status
 */
export const usePatchConsultationsIdStatusForce = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchConsultationsIdStatusForce>>,
    TError,
    { id: string; data: BodyType<ForceUpdateConsultationStatusRequest> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions =
    getPatchConsultationsIdStatusForceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get detail consultation
 */
export const getConsultationsId = (
  id: string,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetConsultationsId200>(
    { url: `/consultations/${id}`, method: 'get', signal },
    options
  );
};

export const getGetConsultationsIdQueryKey = (id: string) =>
  [`/consultations/${id}`] as const;

export const getGetConsultationsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getConsultationsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConsultationsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getConsultationsId>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsultationsIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConsultationsId>>
  > = ({ signal }) => getConsultationsId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetConsultationsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConsultationsId>>
>;
export type GetConsultationsIdQueryError = ErrorType<Error>;

/**
 * @summary Get detail consultation
 */
export const useGetConsultationsId = <
  TData = Awaited<ReturnType<typeof getConsultationsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConsultationsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConsultationsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Aggregate consultation's performance for lawyer
 */
export const getConsultationsAggregate = (
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetConsultationsAggregate200>(
    { url: `/consultations/aggregate`, method: 'get', signal },
    options
  );
};

export const getGetConsultationsAggregateQueryKey = () =>
  [`/consultations/aggregate`] as const;

export const getGetConsultationsAggregateQueryOptions = <
  TData = Awaited<ReturnType<typeof getConsultationsAggregate>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getConsultationsAggregate>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getConsultationsAggregate>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConsultationsAggregateQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConsultationsAggregate>>
  > = ({ signal }) => getConsultationsAggregate(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetConsultationsAggregateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConsultationsAggregate>>
>;
export type GetConsultationsAggregateQueryError = ErrorType<Error>;

/**
 * @summary Aggregate consultation's performance for lawyer
 */
export const useGetConsultationsAggregate = <
  TData = Awaited<ReturnType<typeof getConsultationsAggregate>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getConsultationsAggregate>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConsultationsAggregateQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Subscribe user on their first time login
 */
export const postConsultationsSubscribeUser = (
  subscribeUser: BodyType<SubscribeUser>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  const formData = new FormData();
  formData.append('id', subscribeUser.id);

  return caseServiceRequest<void>(
    {
      url: `/consultations/subscribe-user`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getPostConsultationsSubscribeUserMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postConsultationsSubscribeUser>>,
    TError,
    { data: BodyType<SubscribeUser> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postConsultationsSubscribeUser>>,
  TError,
  { data: BodyType<SubscribeUser> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postConsultationsSubscribeUser>>,
    { data: BodyType<SubscribeUser> }
  > = (props) => {
    const { data } = props ?? {};

    return postConsultationsSubscribeUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostConsultationsSubscribeUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof postConsultationsSubscribeUser>>
>;
export type PostConsultationsSubscribeUserMutationBody =
  BodyType<SubscribeUser>;
export type PostConsultationsSubscribeUserMutationError = ErrorType<Error>;

/**
 * @summary Subscribe user on their first time login
 */
export const usePostConsultationsSubscribeUser = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postConsultationsSubscribeUser>>,
    TError,
    { data: BodyType<SubscribeUser> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions =
    getPostConsultationsSubscribeUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Current Lawyer Consultation Workload Status
 */
export const consultationLawyerStatus = (
  params: ConsultationLawyerStatusParams,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<ConsultationLawyerWorkloadStatus>(
    { url: `/consultations/lawyer_status`, method: 'get', params, signal },
    options
  );
};

export const getConsultationLawyerStatusQueryKey = (
  params: ConsultationLawyerStatusParams
) => [`/consultations/lawyer_status`, ...(params ? [params] : [])] as const;

export const getConsultationLawyerStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof consultationLawyerStatus>>,
  TError = ErrorType<Error>,
>(
  params: ConsultationLawyerStatusParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof consultationLawyerStatus>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof consultationLawyerStatus>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getConsultationLawyerStatusQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof consultationLawyerStatus>>
  > = ({ signal }) => consultationLawyerStatus(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ConsultationLawyerStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof consultationLawyerStatus>>
>;
export type ConsultationLawyerStatusQueryError = ErrorType<Error>;

/**
 * @summary Current Lawyer Consultation Workload Status
 */
export const useConsultationLawyerStatus = <
  TData = Awaited<ReturnType<typeof consultationLawyerStatus>>,
  TError = ErrorType<Error>,
>(
  params: ConsultationLawyerStatusParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof consultationLawyerStatus>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getConsultationLawyerStatusQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get total client of respective lawyer
 */
export const getConsultationsTotalClient = (
  params: GetConsultationsTotalClientParams,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetConsultationsTotalClient200>(
    { url: `/consultations/total_client`, method: 'get', params, signal },
    options
  );
};

export const getGetConsultationsTotalClientQueryKey = (
  params: GetConsultationsTotalClientParams
) => [`/consultations/total_client`, ...(params ? [params] : [])] as const;

export const getGetConsultationsTotalClientQueryOptions = <
  TData = Awaited<ReturnType<typeof getConsultationsTotalClient>>,
  TError = ErrorType<Error>,
>(
  params: GetConsultationsTotalClientParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConsultationsTotalClient>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getConsultationsTotalClient>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConsultationsTotalClientQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConsultationsTotalClient>>
  > = ({ signal }) =>
    getConsultationsTotalClient(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetConsultationsTotalClientQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConsultationsTotalClient>>
>;
export type GetConsultationsTotalClientQueryError = ErrorType<Error>;

/**
 * @summary Get total client of respective lawyer
 */
export const useGetConsultationsTotalClient = <
  TData = Awaited<ReturnType<typeof getConsultationsTotalClient>>,
  TError = ErrorType<Error>,
>(
  params: GetConsultationsTotalClientParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConsultationsTotalClient>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConsultationsTotalClientQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Reallocate consultation's lawyer
 */
export const patchConsultationsIdReallocateLawyer = (
  id: string,
  reallocateLawyer: BodyType<ReallocateLawyer>,
  options?: SecondParameter<typeof caseServiceRequest>
) => {
  return caseServiceRequest<void>(
    {
      url: `/consultations/${id}/reallocate-lawyer`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: reallocateLawyer,
    },
    options
  );
};

export const getPatchConsultationsIdReallocateLawyerMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchConsultationsIdReallocateLawyer>>,
    TError,
    { id: string; data: BodyType<ReallocateLawyer> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchConsultationsIdReallocateLawyer>>,
  TError,
  { id: string; data: BodyType<ReallocateLawyer> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchConsultationsIdReallocateLawyer>>,
    { id: string; data: BodyType<ReallocateLawyer> }
  > = (props) => {
    const { id, data } = props ?? {};

    return patchConsultationsIdReallocateLawyer(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchConsultationsIdReallocateLawyerMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchConsultationsIdReallocateLawyer>>
>;
export type PatchConsultationsIdReallocateLawyerMutationBody =
  BodyType<ReallocateLawyer>;
export type PatchConsultationsIdReallocateLawyerMutationError =
  ErrorType<Error>;

/**
 * @summary Reallocate consultation's lawyer
 */
export const usePatchConsultationsIdReallocateLawyer = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchConsultationsIdReallocateLawyer>>,
    TError,
    { id: string; data: BodyType<ReallocateLawyer> },
    TContext
  >;
  request?: SecondParameter<typeof caseServiceRequest>;
}) => {
  const mutationOptions =
    getPatchConsultationsIdReallocateLawyerMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get client user type
 */
export const getConsultationsUserType = (
  params: GetConsultationsUserTypeParams,
  options?: SecondParameter<typeof caseServiceRequest>,
  signal?: AbortSignal
) => {
  return caseServiceRequest<GetConsultationsUserType200>(
    { url: `/consultations/user_type`, method: 'get', params, signal },
    options
  );
};

export const getGetConsultationsUserTypeQueryKey = (
  params: GetConsultationsUserTypeParams
) => [`/consultations/user_type`, ...(params ? [params] : [])] as const;

export const getGetConsultationsUserTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getConsultationsUserType>>,
  TError = ErrorType<Error>,
>(
  params: GetConsultationsUserTypeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConsultationsUserType>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getConsultationsUserType>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetConsultationsUserTypeQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getConsultationsUserType>>
  > = ({ signal }) => getConsultationsUserType(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetConsultationsUserTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConsultationsUserType>>
>;
export type GetConsultationsUserTypeQueryError = ErrorType<Error>;

/**
 * @summary Get client user type
 */
export const useGetConsultationsUserType = <
  TData = Awaited<ReturnType<typeof getConsultationsUserType>>,
  TError = ErrorType<Error>,
>(
  params: GetConsultationsUserTypeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getConsultationsUserType>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof caseServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConsultationsUserTypeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
