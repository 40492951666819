import { useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { Button, Grid, Modal, Select, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { usePWA } from 'hooks/usePWA';
import { tinodeAdapter } from 'libs/tinode/tinode-adapter';
import lifecycle from 'page-lifecycle';

import { useGetAuthenticationsMe } from 'api/generated/services/auth/authentication/authentication';
import { usePatchUsersConsultIdPresence } from 'api/generated/services/auth/user/user';
import { IdleModal } from 'components/modals/idle';
import { useAuthStore } from 'store/auth';
import { useChatStore } from 'store/chat';
import { useCurrentUserStore } from 'store/current-user';

import { STATUS_SELECT_STATUS_OPTIONS } from '../constants';

const MILLISECOND = 1000;
const SECOND = 60;
const MINUTES = (minute: number) => minute * SECOND * MILLISECOND;

const IDLE_TIMEOUT = MINUTES(10);

export function StatusSelect() {
  const screens = Grid.useBreakpoint();
  const [isOfflineConfirmationOpened, setIsOfflineConfirmationOpened] =
    useState<boolean>(false);
  const [isIdleConfirmationOpened, setIsIdleConfirmationOpened] =
    useState<boolean>(false);
  const ongoingConsultations = useChatStore(
    (state) => state.ongoingConsultations
  );
  const consultId = useCurrentUserStore((state) => state.consultId);
  const isOnline = useCurrentUserStore((state) => state.isOnline);
  const setCurrentUser = useCurrentUserStore(
    (state) => state.actions.setCurrentUser
  );
  const [validateStatus, setValidateStatus] = useState(true);

  const isSuspended = useAuthStore(
    (state) => state.suspendedStatus.isSuspended
  );

  const { isPWA } = usePWA();

  const latestEndTime = useMemo<number | null>(() => {
    if (!ongoingConsultations?.length) return null;

    const endTimes =
      ongoingConsultations
        ?.map((consultation) =>
          consultation?.started_at && consultation?.duration
            ? consultation.started_at + consultation.duration
            : 0
        )
        ?.filter(Boolean) || [];

    if (!endTimes?.length) return null;

    return Math.max(...endTimes);
  }, [ongoingConsultations]);

  const { isFetching: isLoadingRefetchMe } = useGetAuthenticationsMe({
    query: {
      onSuccess(data) {
        const [navigationEntry] = performance.getEntriesByType(
          'navigation'
        ) as PerformanceNavigationTiming[];
        const lastStatus = sessionStorage.getItem('lastStatus');

        setCurrentUser({
          isOnline: isSuspended ? false : data?.data?.is_online,
        });

        if (
          navigationEntry.type === 'reload' &&
          lastStatus === 'online' &&
          !isSuspended
        ) {
          handleChange('online');
          sessionStorage.removeItem('isReloadOnline');
        } else {
          setValidateStatus(false);
        }
      },
      enabled: false,
    },
  });

  const { mutate: patchUserPresence, isLoading: isLoadingPatchUserPresence } =
    usePatchUsersConsultIdPresence({
      mutation: {
        onSuccess: (_, res) => {
          setCurrentUser({
            isOnline: res?.data?.is_online,
          });
          setValidateStatus(false);
          setIsOfflineConfirmationOpened(false);
        },
      },
    });

  const handleChange = async (value: string) => {
    const toBeOnline = value === 'online';

    if (consultId) {
      patchUserPresence({
        consultId,
        data: {
          is_online: toBeOnline,
        },
      });
    }

    if (toBeOnline) {
      await tinodeAdapter.subscribeMe();
    } else {
      await tinodeAdapter.leaveMe();
    }
  };

  const confirmChange = (value: string) => {
    const toBeOnline = value === 'online';

    if (
      !toBeOnline &&
      ongoingConsultations?.length &&
      typeof latestEndTime === 'number'
    ) {
      setIsOfflineConfirmationOpened(true);
    } else {
      handleChange(value);
    }
  };

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: () => {
      if (isOnline) {
        setIsIdleConfirmationOpened(true);
        handleChange('offline');
      }
    },
  });

  useEffect(() => {
    const handleBeforeCloseTab = () => {
      sessionStorage.setItem('lastStatus', isOnline ? 'online' : 'offline');
      if (isOnline) {
        handleChange('offline');
        sessionStorage.setItem('isReloadOnline', 'true');
      }
    };

    const handleOfflinePWA = ({ newState }: { newState: string }) => {
      const isReloadOnline = sessionStorage.getItem('isReloadOnline');
      // Set Offline every hidden
      if (newState === 'hidden' && isPWA && isOnline && !isReloadOnline) {
        handleChange('offline');
      }
    };

    window.addEventListener('beforeunload', handleBeforeCloseTab);
    lifecycle.addEventListener('statechange', handleOfflinePWA);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeCloseTab);
      lifecycle.removeEventListener('statechange', handleOfflinePWA);
    };
    // eslint-disable-next-line
  }, [isOnline, isPWA]);

  const loadingSelect = useMemo(
    () => isLoadingPatchUserPresence || isLoadingRefetchMe || validateStatus,
    [isLoadingPatchUserPresence, isLoadingRefetchMe, validateStatus]
  );

  useEffect(() => {
    if (isSuspended) {
      handleChange('offline');
    }
    // eslint-disable-next-line
  }, [isSuspended]);

  return (
    <>
      <Select
        className="status-select"
        defaultValue="offline"
        disabled={isSuspended}
        loading={loadingSelect}
        onChange={confirmChange}
        options={STATUS_SELECT_STATUS_OPTIONS}
        size={screens.md ? 'middle' : 'small'}
        value={loadingSelect ? 'Loading' : isOnline ? 'online' : 'offline'}
      />

      <Modal
        centered
        className="confirmation-modal"
        footer={null}
        onCancel={() => setIsOfflineConfirmationOpened(false)}
        open={isOfflineConfirmationOpened}
        title="Ubah status menjadi offline"
        width={420}>
        <Space className="w-full" direction="vertical" size={16}>
          <Typography.Text>
            Status akan diperbaharui menjadi <strong>Offline</strong> setelah
            (semua) konsultasi berakhir
            {typeof latestEndTime === 'number' && (
              <Typography.Text>
                {' '}
                pada{' '}
                <strong>{dayjs.unix(latestEndTime).format('HH:mm')}</strong>.
              </Typography.Text>
            )}
          </Typography.Text>
          <Button block onClick={() => handleChange('offline')} type="primary">
            Ya, Aktifkan
          </Button>
        </Space>
      </Modal>

      <IdleModal
        handleOffline={() => {
          setIsIdleConfirmationOpened(false);
        }}
        handleOnline={() => {
          handleChange('online');
          setIsIdleConfirmationOpened(false);
        }}
        open={isIdleConfirmationOpened}
      />
    </>
  );
}
