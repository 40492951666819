import { useMemo } from 'react';

import { Card, Col, Divider, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';

import { LegalDraft, Payment, User } from 'api/generated/models/case';
import { Avatar } from 'components/avatar';
import { currencyFormatter } from 'utils';
import { getSecretFullName } from 'utils/get-full-name';

interface ForumPaymentCardProps {
  dueDate: string;
  user: User;
  payment: Payment | undefined;
  legalDraft: LegalDraft | undefined;
}

export const ForumPaymentCard = ({
  dueDate,
  user,
  payment,
  legalDraft,
}: ForumPaymentCardProps) => {
  const clientFullName = getSecretFullName(user);
  const caseNumber = `#${legalDraft?.case?.code ?? '-'}`;

  const paymentInfo = useMemo(() => {
    const amount = currencyFormatter(Number(payment?.log?.amount ?? 0));
    const date = dayjs(payment?.log?.paid_at).format('DD/MM/YYYY');

    return [
      {
        label: 'Tanggal',
        value: date,
      },
      {
        label: 'Batas Pengerjaan',
        value: dueDate,
      },
      {
        label: 'Harga',
        value: amount,
      },
    ];
  }, [dueDate, payment?.log?.amount, payment?.log?.paid_at]);

  return (
    <Card className="forum-payment-card">
      <Space align="start" direction="vertical" size={6}>
        <Avatar size={50} user={user} />
        <Typography.Text className="font-16 weight-500 hk-color-black-title">
          {clientFullName}
        </Typography.Text>
        <Typography.Text className="font-14 weight-400 hk-color-dark-grey">
          No. Kasus <span className="hk-color-black-title">{caseNumber}</span>
        </Typography.Text>
      </Space>
      <Divider />
      {paymentInfo.map(({ label, value }) => {
        return (
          <Row
            align="middle"
            className="w-full"
            gutter={[0, 6]}
            justify="space-between"
            key={label}>
            <Col>
              <Typography.Text className="font-12 weight-400 hk-color-dark-grey">
                {label}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className="font-12 weight-400 hk-color-black-title">
                {value}
              </Typography.Text>
            </Col>
          </Row>
        );
      })}
    </Card>
  );
};
