import './style.scss';

import { Col, Layout, Row, Typography } from 'antd';

export default function PageFooter() {
  return (
    <Layout.Footer className="page-footer">
      <Row gutter={[6, 6]} justify="space-between">
        <Col className="left-section" md={8} xs={24}>
          <Typography.Text>
            © {new Date().getFullYear()} Hukumku
          </Typography.Text>
        </Col>
        <Col className="right-section" md={8} xs={24}>
          <Typography.Link
            className="link"
            href="https://www.hukumku.id/syarat-dan-ketentuan-advokat"
            target="_blank">
            Syarat dan Ketentuan
          </Typography.Link>
        </Col>
      </Row>
    </Layout.Footer>
  );
}
