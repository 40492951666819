import './style.scss';

import { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  ModalProps,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { uniqBy } from 'lodash-es';
import { useDebounce } from 'usehooks-ts';

import {
  ConsultationBasicCase,
  GetConsultationsParams,
  User,
} from 'api/generated/models/case';
import { useGetPracticeAreas } from 'api/generated/services/auth/practice-area/practice-area';
import {
  useGetConsultations,
  useGetConsultationsId,
} from 'api/generated/services/case/consultation/consultation';
import { usePostLegalDrafts } from 'api/generated/services/case/legal-draft/legal-draft';
import {
  useGetOrdersId,
  usePaymentInvoice,
} from 'api/generated/services/case/order/order';
import { FormError } from 'components';
import { priceFormatter } from 'utils';
import { getSecretFullName } from 'utils/get-full-name';

export const DATE_FORMAT = 'DD/MM/YYYY';

export interface CreateOfferModalProps extends ModalProps {
  onCancel: () => void;
  orderId?: string | undefined;
  consultationId?: string | undefined;
  action: 'view' | 'create-fresh' | 'create-from-chat';
}

export const CreateOfferModal = (props: CreateOfferModalProps) => {
  const queryClient = useQueryClient();
  const { orderId, consultationId, action } = props;

  const isCreateOfferFromChat = !!orderId;

  const [form] = Form.useForm();
  const todayDate = dayjs().format(DATE_FORMAT);
  const [customerNameQuery, setCustomerNameQuery] = useState('');
  const [selectedCustomerName, setSelectedCustomerName] = useState<
    null | string
  >(null);
  const [selectedConsultation, setSelectedConsultation] = useState<
    ConsultationBasicCase | undefined
  >(undefined);

  const { data: orderDetail } = useGetOrdersId(orderId ?? '', {
    query: {
      enabled: !!orderId,
    },
  });
  const { data: consultationDetail } = useGetConsultationsId(
    consultationId ?? '',
    {
      query: {
        enabled: !!consultationId,
      },
    }
  );

  const [consultationsParam, setConsultationsParams] =
    useState<GetConsultationsParams>({
      page: 1,
      limit: 100,
      search: '',
      status: ['finished'],
    });

  const { data: consultations, isLoading: isLoadingConsultations } =
    useGetConsultations(consultationsParam);

  const { data: practiceAreas } = useGetPracticeAreas();

  const { mutate: postLegalDraft, isLoading: isLoadingPostLegalDraft } =
    usePostLegalDrafts();
  const { mutate: createOrderInvoice, isLoading: isLoadingCreateOrderInvoice } =
    usePaymentInvoice();

  useEffect(() => {
    if (!consultationDetail) return;
    const clientName = getSecretFullName(
      consultationDetail?.data?.case?.client
    );
    const caseNumber = consultationDetail?.data?.case?.code;
    const practiceArea = {
      label: consultationDetail?.data?.case?.practice_area?.name,
      value: consultationDetail?.data?.case?.practice_area?.id,
    };
    const topic = consultationDetail?.data?.case?.topic;
    setSelectedConsultation(consultationDetail?.data);
    const initForm = () => {
      form.setFieldsValue({
        customer_name: clientName,
        case: caseNumber,
        practice_area: practiceArea,
        topic: topic,
      });
    };
    initForm();
  }, [form, consultationDetail]);

  useEffect(() => {
    if (!orderDetail) return;
    const clientName = getSecretFullName(
      orderDetail?.data?.client_info as User
    );
    const caseNumber = orderDetail?.data?.product?.case?.code;
    const practiceArea = practiceAreas?.data?.find(
      ({ id }) => orderDetail?.data?.product?.case?.practice_area_id === id
    );
    const name = orderDetail?.data?.order_items?.[0]?.detail?.['Name'];
    const description =
      orderDetail?.data?.order_items?.[0]?.detail?.['Description'];
    const dueDate = orderDetail?.data?.order_items?.[0]?.detail?.['DueDate'];
    const amount = orderDetail?.data?.order_items?.[0]?.amount ?? 0;
    const topic = orderDetail?.data?.product?.case?.topic;
    setSelectedConsultation(consultationDetail?.data);
    const initForm = () => {
      form.setFieldsValue({
        amount,
        topic,
        name,
        description,
        customer_name: clientName,
        case: caseNumber,
        due_date: dayjs.unix(dueDate),
        practice_area: {
          label: practiceArea?.name,
          value: practiceArea?.id,
        },
      });
    };
    initForm();
  }, [form, consultationDetail, orderDetail, practiceAreas]);

  const practiceAreaOptions = useMemo(() => {
    return practiceAreas?.data?.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });
  }, [practiceAreas]);

  const customerNameOptions = useMemo(() => {
    const customerNameId = consultations?.data?.map((eachConsultation) => {
      const {
        case: { client },
      } = eachConsultation;

      const clientFullName = getSecretFullName(client);

      return {
        label: clientFullName,
        value: clientFullName,
      };
    });

    const filterSameName = uniqBy(customerNameId, 'label');

    return filterSameName;
  }, [consultations]);

  const caseNumberOptions = useMemo(() => {
    if (!selectedCustomerName) return [];
    const caseNumberList = consultations?.data
      ?.filter((eachConsultation) => {
        const {
          case: { client },
        } = eachConsultation;

        return getSecretFullName(client) === selectedCustomerName;
      })
      ?.map((eachFilteredConsultation) => {
        const {
          case: { code },
        } = eachFilteredConsultation;
        return {
          label: code,
          value: code,
        };
      });
    return caseNumberList;
  }, [consultations, selectedCustomerName]);

  const debouncedCustomerNameQuery = useDebounce(customerNameQuery, 500);

  useEffect(() => {
    setConsultationsParams((prevState) => ({
      ...prevState,
      search: debouncedCustomerNameQuery,
    }));
  }, [debouncedCustomerNameQuery]);

  const onChangeSelectedCustomerName = (custName: string) => {
    form.setFieldValue('case', null);
    setSelectedCustomerName(custName);
  };

  const onChangeSelectedCaseNumber = (caseNumberInput: string) => {
    const findSelectedConsultation = consultations?.data?.find(
      (eachObj) => eachObj?.case?.code === caseNumberInput
    );

    setSelectedConsultation(findSelectedConsultation);

    const prefillPracticeArea = practiceAreaOptions?.filter(({ value }) => {
      return value === findSelectedConsultation?.case?.practice_area_id;
    });
    form.setFieldValue('practice_area', prefillPracticeArea);
    form.setFieldValue('topic', findSelectedConsultation?.case?.topic);
  };

  const oSearchCustomer = (value: string) => {
    setCustomerNameQuery(value);
  };

  const onSubmitCreateOffer = () => {
    const currentForm = form.getFieldsValue();
    const payload = {
      case_id: selectedConsultation?.case_id ?? '',
      items: [
        {
          name: currentForm.name,
          amount: Number(currentForm.amount),
          description: currentForm.description,
          due_date: dayjs(currentForm.due_date).unix(),
        },
      ],
    };

    // order invoice

    postLegalDraft(
      {
        data: payload,
      },
      {
        onSuccess: async (resp) => {
          const orderIdInvoice = resp.data.order_id;
          createOrderInvoice(
            {
              id: orderIdInvoice,
            },
            {
              onSuccess: async () => {
                props?.onCancel?.();
                form.resetFields();
                message.success('Sukses membuat penawaran');
                await queryClient.invalidateQueries();
              },
            }
          );
        },
      }
    );
  };

  return (
    <Modal
      {...props}
      className="legal-draft-modal"
      footer={false}
      title={
        <Typography.Title
          className="legal-draft-modal-title weight-500"
          color="#121926">
          Buat Penawaran
        </Typography.Title>
      }>
      <Typography.Paragraph
        className="font-14 weight-500 hk-color-dark-grey"
        color="#787872">
        DETAIL PENAWARAN
      </Typography.Paragraph>
      <Form
        className="w-full"
        form={form}
        initialValues={{ created_at: dayjs(todayDate, DATE_FORMAT) }}
        layout="vertical"
        onFinish={onSubmitCreateOffer}
        requiredMark={false}>
        <Row className="hk-margin-bottom-none" gutter={[12, 0]}>
          <Col md={12} xs={24}>
            <Form.Item
              label="Tanggal"
              name="created_at"
              rules={[{ required: true, message: 'Masukkan tanggal' }]}>
              <DatePicker
                className="w-full"
                disabled
                format={DATE_FORMAT}
                placeholder={DATE_FORMAT}
                value={dayjs()}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              className="w-full"
              label="Batas Waktu Pengerjaan"
              name="due_date"
              rules={[
                { required: true, message: 'Masukkan batas waktu pengerjaan' },
              ]}>
              <DatePicker
                className="w-full"
                disabled={action === 'view'}
                disabledDate={(current: any) => {
                  const customDate = dayjs().format(DATE_FORMAT);
                  return current && current < dayjs(customDate, DATE_FORMAT);
                }}
                format={DATE_FORMAT}
                placeholder={DATE_FORMAT}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label="Nama"
              name="customer_name"
              rules={[{ required: true, message: 'Masukkan nama klien' }]}>
              <Select
                disabled={
                  isCreateOfferFromChat || action === 'create-from-chat'
                }
                loading={isLoadingConsultations}
                notFoundContent={
                  isLoadingConsultations ? (
                    <Spin size="small" />
                  ) : (
                    <p className="hk-padding-sm">Not Found</p>
                  )
                }
                onChange={onChangeSelectedCustomerName}
                onSearch={oSearchCustomer}
                options={customerNameOptions}
                placeholder="Pilih"
                showSearch
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              label="No. Kasus"
              name="case"
              rules={[{ required: true, message: 'Masukkan nomor kasus' }]}>
              <Select
                disabled={!selectedCustomerName}
                loading={isLoadingConsultations}
                onChange={onChangeSelectedCaseNumber}
                options={caseNumberOptions}
                placeholder="Pilih"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              className="hk-margin-bottom-none"
              label="Harga"
              name="amount"
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        <FormError message="Masukkan jumlah penawaran yang akan ditarik" />
                      );
                    }

                    if (value < 50000) {
                      return Promise.reject(
                        <FormError message="Minimum Penawaran Rp 50.000" />
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}>
              <InputNumber
                className="w-full"
                disabled={action === 'view'}
                formatter={(value) => {
                  if (!value) return '';
                  return priceFormatter(value);
                }}
                prefix={
                  <Typography.Paragraph className="font-14 weight-400 hk-color-heading-2 hk-margin-bottom-none">
                    Rp
                  </Typography.Paragraph>
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider className="hk-margin-top-md hk-margin-bottom-md" />
        <Typography.Paragraph
          className="font-14 weight-500 hk-color-dark-grey"
          color="#787872">
          DETAIL DOKUMEN
        </Typography.Paragraph>
        <Row gutter={[12, 0]}>
          <Col md={12} xs={24}>
            <Form.Item label="Area Praktik" name="practice_area">
              <Select
                disabled
                options={practiceAreaOptions}
                placeholder="Pilih"
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label="Topik" name="topic">
              <Select disabled placeholder="Pilih" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Judul"
              name="name"
              rules={[{ required: true, message: 'Masukkan judul dokumen' }]}>
              <Input
                disabled={action === 'view'}
                placeholder="Tulis judul disini"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Deskripsi"
              name="description"
              rules={[
                { required: true, message: 'Masukkan deskripsi dokumen' },
              ]}>
              <Input.TextArea
                disabled={action === 'view'}
                placeholder="Tulis detail deskripsi disini..."
                rows={4}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Action */}
        <Row gutter={[12, 12]}>
          <Col
            md={{ span: action === 'view' ? 24 : 12, order: 1 }}
            xs={{ span: 24, order: 2 }}>
            <Button
              className="w-full"
              disabled={isLoadingPostLegalDraft || isLoadingCreateOrderInvoice}
              loading={isLoadingPostLegalDraft || isLoadingCreateOrderInvoice}
              onClick={() => props?.onCancel?.()}>
              Batal
            </Button>
          </Col>
          <Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
            {(action === 'create-fresh' || action === 'create-from-chat') && (
              <Button
                className="w-full"
                disabled={
                  isLoadingPostLegalDraft || isLoadingCreateOrderInvoice
                }
                htmlType="submit"
                loading={isLoadingPostLegalDraft || isLoadingCreateOrderInvoice}
                type="primary">
                Ajukan
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
