import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { tinodeAdapter } from 'libs/tinode/tinode-adapter';

import { useGetAuthenticationsProviderLogout } from 'api/generated/services/auth/authentication/authentication';
import { usePatchUsersConsultIdPresence } from 'api/generated/services/auth/user/user';
import { LOCALSTORAGE_KEY } from 'config/constants';
import { useAuthStore } from 'store/auth';
import { useChatStore } from 'store/chat';
import { useCurrentUserStore } from 'store/current-user';

export function useLogout() {
  const navigate = useNavigate();
  const resetAuth = useAuthStore((state) => state.actions.reset);
  const user = useAuthStore((state) => state.user);
  const consultId = useCurrentUserStore((state) => state.consultId);
  const isOnline = useCurrentUserStore((state) => state.isOnline);
  const resetCurrentUser = useCurrentUserStore((state) => state.actions.reset);
  const resetChat = useChatStore((state) => state.actions.reset);

  const { refetch: logoutDirect } = useGetAuthenticationsProviderLogout(
    'direct',
    {
      query: {
        enabled: false,
        onSuccess() {
          localStorage.removeItem(LOCALSTORAGE_KEY.showGuideline);
          localStorage.clear();
          resetCurrentUser();
          resetChat();
          resetAuth();
          navigate('/auth/login');
        },
      },
    }
  );

  const { mutate: patchUserPresence } = usePatchUsersConsultIdPresence({
    mutation: {
      onSuccess() {
        logoutDirect();
      },
    },
  });

  const logout = useCallback(async () => {
    if (!user) {
      navigate('/auth/login');
    }

    try {
      await tinodeAdapter.logout();
    } catch (error) {
      // continue on tinode logout failure
    }

    if (consultId && isOnline) {
      await patchUserPresence({
        consultId,
        data: {
          is_online: false,
        },
      });
    } else {
      await logoutDirect();
    }
  }, [consultId, isOnline, logoutDirect, navigate, patchUserPresence, user]);

  return {
    logout,
  };
}
