export const snakeToTitleCase = (text: string) => {
  if (!text) return text;
  return text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const secretString = (text: string) => {
  if (!text) return text;
  const strings = text
    .split(' ')
    .map((item) => item?.charAt(0) + '*'.repeat((item?.length || 1) - 1));

  return strings.join(' ');
};
