/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * HukumkuRestAPI-DocumentService
 * OpenAPI spec version: 1.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  BodyType,
  ErrorType,
} from '../../../../utils/document-service-request';
import { documentServiceRequest } from '../../../../utils/document-service-request';
import type {
  BasicDocumentResponse,
  CategoryTreeResponse,
  DocumentResponse,
  Error,
  GetDocuments200,
  GetDocumentsParams,
  GetLawyerDocumentsParams,
  LawyerDocumentCountResponse,
  LawyerDocumentResponse,
  LawyerDocumentsResponse,
  TopLawyerDocumentsResponse,
  Upload,
} from '../../../models/document';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * @summary Upload file
 */
export const postDocuments = (
  upload: BodyType<Upload>,
  options?: SecondParameter<typeof documentServiceRequest>
) => {
  const formData = new FormData();
  formData.append('file', upload.file);
  formData.append('reference_id', upload.reference_id);
  formData.append('reference_type', upload.reference_type);
  formData.append('document_type', upload.document_type);
  if (upload.name !== undefined) {
    formData.append('name', upload.name);
  }
  if (upload.id !== undefined) {
    formData.append('id', upload.id);
  }
  if (upload.delete !== undefined) {
    formData.append('delete', upload.delete.toString());
  }

  return documentServiceRequest<BasicDocumentResponse>(
    {
      url: `/documents`,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getPostDocumentsMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDocuments>>,
    TError,
    { data: BodyType<Upload> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postDocuments>>,
  TError,
  { data: BodyType<Upload> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postDocuments>>,
    { data: BodyType<Upload> }
  > = (props) => {
    const { data } = props ?? {};

    return postDocuments(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDocumentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postDocuments>>
>;
export type PostDocumentsMutationBody = BodyType<Upload>;
export type PostDocumentsMutationError = ErrorType<Error>;

/**
 * @summary Upload file
 */
export const usePostDocuments = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDocuments>>,
    TError,
    { data: BodyType<Upload> },
    TContext
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}) => {
  const mutationOptions = getPostDocumentsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get List Document
 */
export const getDocuments = (
  params?: GetDocumentsParams,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<GetDocuments200>(
    { url: `/documents`, method: 'get', params, signal },
    options
  );
};

export const getGetDocumentsQueryKey = (params?: GetDocumentsParams) =>
  [`/documents`, ...(params ? [params] : [])] as const;

export const getGetDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocuments>>,
  TError = ErrorType<Error>,
>(
  params?: GetDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getDocuments>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocuments>>> = ({
    signal,
  }) => getDocuments(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocuments>>
>;
export type GetDocumentsQueryError = ErrorType<Error>;

/**
 * @summary Get List Document
 */
export const useGetDocuments = <
  TData = Awaited<ReturnType<typeof getDocuments>>,
  TError = ErrorType<Error>,
>(
  params?: GetDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDocumentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get signed URL
 */
export const getDocumentsId = (
  id: string,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<DocumentResponse>(
    { url: `/documents/${id}`, method: 'get', signal },
    options
  );
};

export const getGetDocumentsIdQueryKey = (id: string) =>
  [`/documents/${id}`] as const;

export const getGetDocumentsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocumentsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getDocumentsId>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentsIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentsId>>> = ({
    signal,
  }) => getDocumentsId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetDocumentsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentsId>>
>;
export type GetDocumentsIdQueryError = ErrorType<Error>;

/**
 * @summary Get signed URL
 */
export const useGetDocumentsId = <
  TData = Awaited<ReturnType<typeof getDocumentsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDocumentsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get List Lawyer Document
 */
export const getLawyerDocuments = (
  params: GetLawyerDocumentsParams,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentsResponse>(
    { url: `/lawyer_documents`, method: 'get', params, signal },
    options
  );
};

export const getGetLawyerDocumentsQueryKey = (
  params: GetLawyerDocumentsParams
) => [`/lawyer_documents`, ...(params ? [params] : [])] as const;

export const getGetLawyerDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocuments>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocuments>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocuments>>
  > = ({ signal }) => getLawyerDocuments(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocuments>>
>;
export type GetLawyerDocumentsQueryError = ErrorType<Error>;

/**
 * @summary Get List Lawyer Document
 */
export const useGetLawyerDocuments = <
  TData = Awaited<ReturnType<typeof getLawyerDocuments>>,
  TError = ErrorType<Error>,
>(
  params: GetLawyerDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocuments>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Lawyer Document Detail
 */
export const getLawyerDocumentsId = (
  id: string,
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentResponse>(
    { url: `/lawyer_documents/${id}`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsIdQueryKey = (id: string) =>
  [`/lawyer_documents/${id}`] as const;

export const getGetLawyerDocumentsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsId>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsId>>
  > = ({ signal }) => getLawyerDocumentsId(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetLawyerDocumentsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsId>>
>;
export type GetLawyerDocumentsIdQueryError = ErrorType<Error>;

/**
 * @summary Get Lawyer Document Detail
 */
export const useGetLawyerDocumentsId = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsId>>,
  TError = ErrorType<Error>,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLawyerDocumentsId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof documentServiceRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Lawyer Document Count
 */
export const getLawyerDocumentsCount = (
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<LawyerDocumentCountResponse>(
    { url: `/lawyer_documents/count`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsCountQueryKey = () =>
  [`/lawyer_documents/count`] as const;

export const getGetLawyerDocumentsCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsCountQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsCount>>
  > = ({ signal }) => getLawyerDocumentsCount(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsCount>>
>;
export type GetLawyerDocumentsCountQueryError = ErrorType<Error>;

/**
 * @summary Get Lawyer Document Count
 */
export const useGetLawyerDocumentsCount = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsCount>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Top Lawyer Document
 */
export const getLawyerDocumentsTop = (
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<TopLawyerDocumentsResponse>(
    { url: `/lawyer_documents/top`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsTopQueryKey = () =>
  [`/lawyer_documents/top`] as const;

export const getGetLawyerDocumentsTopQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLawyerDocumentsTopQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsTop>>
  > = ({ signal }) => getLawyerDocumentsTop(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsTopQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsTop>>
>;
export type GetLawyerDocumentsTopQueryError = ErrorType<Error>;

/**
 * @summary Get Top Lawyer Document
 */
export const useGetLawyerDocumentsTop = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsTop>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLawyerDocumentsTopQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Category Tree
 */
export const getLawyerDocumentsGetCategoryTree = (
  options?: SecondParameter<typeof documentServiceRequest>,
  signal?: AbortSignal
) => {
  return documentServiceRequest<CategoryTreeResponse>(
    { url: `/lawyer_documents/get_category_tree`, method: 'get', signal },
    options
  );
};

export const getGetLawyerDocumentsGetCategoryTreeQueryKey = () =>
  [`/lawyer_documents/get_category_tree`] as const;

export const getGetLawyerDocumentsGetCategoryTreeQueryOptions = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLawyerDocumentsGetCategoryTreeQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>
  > = ({ signal }) => getLawyerDocumentsGetCategoryTree(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLawyerDocumentsGetCategoryTreeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>
>;
export type GetLawyerDocumentsGetCategoryTreeQueryError = ErrorType<Error>;

/**
 * @summary Get Category Tree
 */
export const useGetLawyerDocumentsGetCategoryTree = <
  TData = Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
  TError = ErrorType<Error>,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getLawyerDocumentsGetCategoryTree>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof documentServiceRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetLawyerDocumentsGetCategoryTreeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
