// https://chatscope.io/storybook/react/?path=/docs/documentation-recipes--page

import './style.scss';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

import { useMemo, useState } from 'react';

import {
  ChatContainer,
  MainContainer,
  MessageInput,
  MessageList,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react';
import { Result, Typography } from 'antd';
import dayjs from 'dayjs';
import { Message as TinodeMessage } from 'libs/tinode/types/sdk/Message';
import { stripHtml } from 'string-strip-html';

import { BasicDocument } from 'api/generated/models/document';
import { ReactComponent as VerificationWaitingIc } from 'assets/svg/verification-waiting-ic.svg';
import { EmptyList } from 'components/empty-list';
import { MESSAGE_VIOLATION_ERROR } from 'config/constants';
import { useCurrentUserStore } from 'store/current-user';
import { ConsultationStatus } from 'types/enums';
import { getStartMessage } from 'utils/get-start-message';

import { ChatHead } from './components/chat-head';
import { ClientIssue } from './components/client-issue/ClientIssue';
import { RenderMessageList } from './components/message-list';
import { UploadFileModal } from './components/upload-file-modal';
import { ViolateModal } from './components/violate-modal';
import { INPUT_ENABLED_CONSULTATION_STATUS } from './constants';
import { ChatProps } from './types';

export const Chat = ({
  consultation,
  hasWriteAccess = false,
  isBackButtonVisible,
  messages,
  onSend,
  onSuccessfullyEnded,
}: ChatProps) => {
  const [isViolateModalOpened, setIsViolateModalOpened] = useState(false);
  const [isUploadModalOpened, setIsUploadModalOpened] =
    useState<boolean>(false);
  const consultId = useCurrentUserStore((state) => state.consultId || '');

  const startMessage = getStartMessage(messages);
  const isInputAllowed =
    hasWriteAccess &&
    startMessage &&
    INPUT_ENABLED_CONSULTATION_STATUS.includes(
      consultation?.status as ConsultationStatus
    );
  const formattedStartedAt = startMessage?.ts
    ? dayjs(startMessage.ts).format('DD MMMM YYYY | HH:mm')
    : null;
  const formattedFinishedAt = consultation?.ended_at
    ? dayjs.unix(consultation.ended_at).format('DD MMMM YYYY | HH:mm')
    : null;

  const userMessages = useMemo(() => {
    const nonSystemMessages =
      messages?.filter((message) => message.content.type !== 'system') || [];

    // Append end time to messages because end time can be placed before the last message from lawyer
    if (consultation?.status === ConsultationStatus.finished) {
      const endMessage: TinodeMessage = {
        content: {
          txt: {
            message: '',
          },
          type: 'system',
          message: formattedFinishedAt
            ? `Konsultasi selesai pada ${formattedFinishedAt}`
            : 'Konsultasi selesai',
        },
        from: '',
        seq: -1,
        ts: consultation?.ended_at
          ? new Date(consultation?.ended_at * 1000)
          : new Date(),
      };
      nonSystemMessages.push(endMessage);
    }

    return nonSystemMessages.sort((left, right) => {
      if (left.ts < right.ts) return -1;
      if (left.ts > right.ts) return 1;
      return 0;
    });
  }, [
    consultation?.ended_at,
    consultation?.status,
    formattedFinishedAt,
    messages,
  ]);

  const isHasActiveOrderOffer = useMemo(() => {
    const checkActive = userMessages.some(({ content }) => {
      return content.on_behalf === 'lawyer' && content.type === 'order';
    });
    return checkActive;
  }, [userMessages]);

  const handleSend = (message: any) => {
    const sanitizedMessage = stripHtml(message)?.result || '';
    if (!sanitizedMessage) return;
    onSend?.({
      txt: {
        message: sanitizedMessage,
        type: 'text',
      },
    });
  };

  const handleUpload = (document: BasicDocument) => {
    onSend?.({
      txt: {
        message: document.id,
        type: 'document',
      },
    });
    setIsUploadModalOpened(false);
  };

  const onOpenViolatedModal = () => setIsViolateModalOpened(true);
  const onCloseViolatedModal = () => setIsViolateModalOpened(false);

  return (
    <div className="main-chat-container">
      <MainContainer className="main-chat-wrapper">
        <ChatHead
          consultation={consultation}
          hasStartMessage={Boolean(startMessage)}
          isBackButtonVisible={isBackButtonVisible}
          isHasActiveOrderOffer={isHasActiveOrderOffer}
          onSuccessfullyEnded={onSuccessfullyEnded}
        />
        <ClientIssue consultationCase={consultation?.case} />
        <ChatContainer>
          <MessageList autoScrollToBottom={true}>
            {!history && !startMessage && (
              <MessageSeparator className="hk-margin-bottom-md flex center">
                <Result
                  icon={
                    <VerificationWaitingIc className="main-chat-empty-state-icon" />
                  }
                  subTitle="Pesan bisa dimulai ketika klien sudah terhubung"
                  title="Menunggu kesiapan klien"
                />
              </MessageSeparator>
            )}

            {Boolean(startMessage) && (
              <MessageSeparator className="hk-margin-bottom-md">
                <Typography.Text className="w-full hk-align-text-center font-12 weight-400 hk-color-black-title">
                  {formattedStartedAt
                    ? `Konsultasi dimulai pada ${formattedStartedAt}`
                    : null}
                </Typography.Text>
              </MessageSeparator>
            )}

            {userMessages?.map((message) => (
              <RenderMessageList
                consultId={consultId}
                eachMessage={message}
                key={message.seq}
                onOpenViolatedModal={onOpenViolatedModal}
              />
            ))}

            {hasWriteAccess &&
              consultation?.status === ConsultationStatus.finished && (
                <MessageSeparator className="last-message-info hk-margin-top-md hk-margin-bottom-md">
                  <Typography.Text className="w-full hk-align-text-center font-12 weight-400 hk-color-black-title">
                    Tulis pesan penutup Anda disini
                  </Typography.Text>
                </MessageSeparator>
              )}

            {consultation?.status !== ConsultationStatus.ongoing &&
              !messages?.length && (
                <EmptyList className="h-full" title="Tidak ada pesan" />
              )}
          </MessageList>

          {isInputAllowed && (
            <MessageInput
              onAttachClick={() => setIsUploadModalOpened(true)}
              onSend={handleSend}
              placeholder="Ketik pesan Anda"
            />
          )}
        </ChatContainer>
      </MainContainer>
      <UploadFileModal
        consultationId={consultation?.id}
        onCancel={() => setIsUploadModalOpened(false)}
        onUpload={handleUpload}
        open={isUploadModalOpened}
      />
      <ViolateModal
        message={MESSAGE_VIOLATION_ERROR}
        onCancel={onCloseViolatedModal}
        open={isViolateModalOpened}
      />
    </div>
  );
};
